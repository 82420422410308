'use strict';

require('phoenix_html');

// require('select2');

// Import local files
//
// Local files can be imported directly using relative
// paths "./socket" or full ones "web/static/js/socket".

// import socket from "./socket"

// usado quando estamos no show client details
// para adicionarmos 1 utilizador ao group
if (document.getElementById('addAccountToGroup')) {
    $('#addAccountToGroup').on('show.bs.modal', function (event) {
        var a = $(event.relatedTarget);
        var data = a.data('account');

        if (data) {
            // veio do account
            $('#accounts').val(data);
        }
    });
} // Brunch automatically concatenates all files in your
// watched paths. Those paths can be configured at
// config.paths.watched in "brunch-config.js".
//
// However, those files will only be executed if
// explicitly imported. The only exception are files
// in vendor, which are never wrapped in imports and
// therefore are always executed.

// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".


$(document).ready(function () {
    if ($('.use-select2').length > 0) {
        $('.use-select2').select2();
    }
});
